.wrapper {
  z-index: 1;
  overflow: hidden;
  position: relative;

  &.withShadow {
    box-shadow:
      0px 0px 2px 0px #919e9b29,
      0px 4px 12px 0px #4c57540f,
      0px 7px 17px 0px #4c575414;
  }

  iframe {
    margin: 0;
    padding: 0;
    border: none;

    width: 100%;
    height: 100%;
  }
}
