/* stylelint-disable import-notation */

@import '../../styles/mixins/breakpoints';

.container {
  ul {
    display: flex;
    gap: 0.875rem;

    li {
      svg {
        color: var(--color-support-300);
      }
    }
  }
}

@include breakpoint('laptop') {
  .container {
    ul {
      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
}
