@mixin breakpoint($size) {
  @if $size == 'tablet' {
    @media (width >= 481px) {
      @content;
    }
  } @else if $size == 'laptop' {
    @media (width >= 769px) {
      @content;
    }
  } @else if $size == 'desktop' {
    @media (width >= 1025px) {
      @content;
    }
  }
}
