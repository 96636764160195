.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border: 1px solid #e3e8e7;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  border-radius: 22px;
  overflow: hidden;
  width: 100%;
}
