/* stylelint-disable no-descending-specificity */
/* stylelint-disable import-notation */

@import '../../styles/mixins/breakpoints';

.container {
  position: relative;
  color: var(--color-text-300);
  flex-shrink: 0;
  padding: 8rem 0 2rem;
  margin-top: auto;

  &::after {
    content: '';
    display: flex;
    width: 200vw;
    height: 100%;
    top: 0;
    left: -100%;
    background-color: var(--color-background-900);
    z-index: -1;
    position: absolute;
  }

  a {
    font-size: 0.75rem;
    font-weight: 600;
  }

  p {
    font-weight: 600;
    font-size: 0.75rem;
  }

  .contact {
    margin-bottom: 1rem;

    h1 {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5rem;
      color: var(--color-text-100);
      max-width: 12ch;
      margin-bottom: 0.625rem;
    }

    a {
      color: var(--color-text-100);
    }
  }

  .links {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 2rem;
    margin-top: 1.5rem;

    li {
      &:nth-child(5) {
        margin-top: 2rem;
      }
    }
  }

  .apps {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2.5rem;

    .title {
      font-weight: 700;
      color: var(--color-text-100);
    }

    a {
      width: 128px;
      padding: 0.5rem;
      background-color: #e9ecef;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: #1f211f;
      border-radius: 4px;
      margin-bottom: 1rem;

      span {
        max-width: 13ch;
        font-size: 0.75rem;
      }
    }
  }
}

@include breakpoint('laptop') {
  .container {
    padding-top: 8rem;

    a {
      font-size: 0.875rem;
      line-height: 140%;
      font-weight: 400;
    }

    .apps {
      margin-bottom: unset;

      .title {
        font-size: 0.875rem;
        align-self: flex-end;
      }

      .appsLinks {
        display: flex;
        gap: 1rem;

        div {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }
      }
    }

    .contact {
      margin-bottom: 2rem;

      h1 {
        font-size: 2rem;
        line-height: 3rem;
      }

      a {
        font-size: 1.25rem;
        font-weight: normal;
      }
    }

    .links {
      margin-bottom: 5rem;
      margin-top: 2rem;

      a {
        color: var(--color-text-100);
        font-weight: 700;
      }
    }

    .flex {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding: 1.25rem 0;
      border-bottom: 1px solid #848c8a;

      p {
        margin-bottom: 0;
        font-size: 0.875rem;
        font-weight: 400;

        a {
          font-weight: 700;
        }
      }
    }

    .copy {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 60%;
      padding: 1.25rem 0;

      p {
        margin: 0;
        font-weight: unset;
      }

      a {
        margin-right: 1rem;
        font-size: 0.875rem;
        font-weight: unset;
      }
    }
  }
}
