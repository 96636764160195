.container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgb(0 0 0 / 60%);

  &.openModal {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s ease-in;
  }

  &.closeModal {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease-in;
  }

  button {
    align-self: flex-end;
    background: none;
    z-index: 100;
    margin-top: 1rem;
    margin-right: 1rem;

    svg {
      path {
        fill: black;
      }
    }
  }

  .content {
    transition: opacity 0.15s ease-in;
    position: fixed;
    display: flex;
    flex-direction: column;
    width: fit-content;
    max-width: 90vw;
    max-height: 100%;
    z-index: 100;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0.5rem;
  }
}
