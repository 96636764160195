.button {
  width: inherit;
  height: inherit;
  padding: 1rem;
  border-radius: 100px;

  &.primary {
    background-color: var(--color-support-300);
    color: var(--color-text-900);
    font-weight: 700;
  }

  &.secondary {
    background-color: transparent;
    border: 1px solid var(--color-brand-500);
    color: var(--color-brand-500);
  }
}
