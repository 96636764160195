/* stylelint-disable import-notation */

@import '../../../styles//mixins/breakpoints';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  padding: 1rem 2rem;
  width: 90vw;

  .description {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5rem;
    text-align: center;
    color: var(--color-text-700);
  }

  p {
    text-align: center;
    color: var(--color-text-700);
    font-size: 0.875rem;
    line-height: 150%;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-brand-100);
    padding: 0.75rem;
    border-radius: 100%;
    margin-bottom: 1rem;
  }

  h1 {
    font-size: 1.25rem;
    text-align: center;
    font-weight: 600;
    color: var(--color-text-900);
  }

  button {
    padding: 1rem 2rem;
    width: fit-content;
    align-self: center;
    font-size: 0.75rem;
  }

  button {
    padding: 1rem 2rem;
    width: fit-content;
    align-self: center;
    font-size: 0.75rem;
  }

  img {
    width: 60px;
  }

  .carriers {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
  }

  .carrierLogo {
    display: flex;
    align-items: center;
  }

  .plusIcon {
    margin: 0 0.8rem;
  }
}

@include breakpoint('laptop') {
  .container {
    width: 500px;

    .apps {
      display: flex;
      flex-direction: column;
      align-items: center;

      div {
        display: flex;
        align-items: center;
        gap: 1rem;
      }

      svg {
        width: 30px;
        height: auto;
      }

      img {
        width: 180px;
      }

      p {
        font-size: 0.875rem;
        font-weight: 400;
        text-align: center;
        width: 240px;
        color: var(--color-text-500);
      }

      a {
        width: 170px;
        display: flex;
        gap: 0.5rem;
        border: 1px solid var(--color-text-900);
        padding: 0.5rem;
        border-radius: 4px;

        span {
          display: flex;
          flex-direction: column;
          font-size: 0.75rem;
          font-weight: 500;

          strong {
            font-size: 1.25rem;
            font-weight: 500;
          }
        }
      }
    }

    h1 {
      font-size: 1.5rem;
      max-width: 30ch;
      letter-spacing: 1px;
    }

    button {
      font-size: 0.875rem;
      padding: 1rem;
    }
  }
}
