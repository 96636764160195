/* stylelint-disable import-notation */

@import '../../../styles/mixins/_breakpoints';

.navContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 0.875rem;
  font-weight: 600;

  li {
    a {
      color: var(--color-text-900);
    }
  }
}

@include breakpoint('desktop') {
  .navContainer {
    flex-direction: row;
    font-weight: unset;
  }
}
