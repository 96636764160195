.sidebarContainer {
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  position: absolute;
  background-color: var(--color-brand-500);
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  transition:
    visibility 0.2s linear,
    opacity 0.2s linear;
  position: fixed;
  left: 0;

  .sidebarContent {
    display: flex;
    flex-direction: column;

    .social {
      align-self: center;
    }
  }

  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.5rem;

    button {
      background-color: transparent;
    }
  }

  nav {
    margin-bottom: 3rem;

    ul {
      li {
        a {
          color: white;
          font-weight: 600;
          font-size: 0.875rem;
        }
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    margin-bottom: 2.5rem;

    a {
      display: inline-block;
      width: 80%;
      border: 1px solid #e0ffee;
      border-radius: 100px;
      text-align: center;
      padding: 0.875rem 0;
      color: white;
      font-size: 0.875rem;
      font-weight: 700;
    }

    button {
      padding: 0.875rem 0;
      font-size: 0.875rem;
      width: 80%;
      border-radius: 100px;
      background-color: var(--color-support-300);
      box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
      font-weight: 700;
    }
  }

  p {
    color: var(--color-brand-100);
    text-align: center;
    font-weight: 600;
    margin-bottom: 2.5rem;

    a {
      color: var(--color-support-300);
      font-weight: 600;
    }
  }

  &.isOpen {
    visibility: visible;
    opacity: 1;

    .sidebarContent {
      display: flex;
    }
  }

  &.isClose {
    visibility: hidden;
    opacity: 0;

    .sidebarContent {
      display: none;
    }
  }
}
