.header {
  top: 0;
  position: sticky;

  @media only screen and (max-width: 1023px) {
    .backdrop {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: fixed;

      user-select: none;
      pointer-events: none;

      opacity: 0;
      width: 100vw;
      height: 100vh;
      background-color: #28302e;
      transition: opacity 0.3s;

      &.active {
        opacity: 0.5;
      }
    }

    .menu {
      height: 100vh;
      background-color: #fff;

      top: 0;
      right: 0;
      position: fixed;

      opacity: 0;
      user-select: none;
      pointer-events: none;

      &.active {
        transition: opacity 0.3s;

        opacity: 1;
        user-select: auto;
        pointer-events: all;
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    &.withBorder {
      border-bottom: 1px solid #edf2f1;
    }

    .menu {
      height: auto;
      background-color: #edf2f1;

      &.closed {
        position: absolute;
        transform: translateY(-100%);
      }
    }
  }
}
