.link {
  position: relative;
  transition: background-color 0.3s;

  &::before,
  &::after {
    content: '';
    bottom: -6px;
    position: absolute;

    opacity: 0;

    width: 20px;
    height: 20px;
    background-color: transparent;

    transition: opacity 0.3s;
  }

  &::before {
    left: -15px;
    border-bottom-right-radius: 15px;
    box-shadow: inset -5px -5px 0 0 white;
  }

  &::after {
    right: -15px;
    border-bottom-left-radius: 15px;
    box-shadow: inset 5px -5px 0 0 white;
  }

  @media only screen and (min-width: 1024px) {
    &.active,
    &:hover {
      &::before,
      &::after {
        opacity: 1;
      }
    }
  }
}
