/* stylelint-disable import-notation */

@import '../../styles/mixins/breakpoints';

.headerContainer {
  padding: 1.25rem 1rem;
  background-color: #edf2f1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .headerContent {
    width: 1200px;
    display: flex;
    justify-content: space-between;
  }

  &::after {
    width: 200%;
    content: '';
    height: 2px;
    background-color: #bbc4c2;
    position: absolute;
    left: -50%;
    top: 77px;
  }

  .logo {
    display: flex;
    align-items: center;
  }

  .sidebarButton {
    transition: transform 0.25s ease;
  }
}

@include breakpoint('desktop') {
  .headerContainer {
    display: flex;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    &::after {
      top: 96.5px;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1.5rem;
    }
  }
}
