/* stylelint-disable import-notation */

@import '../../../styles//mixins/breakpoints';

.container {
  padding: 1rem 2rem;
  width: 90vw;

  h1 {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1rem;
    color: var(--color-text-900);
    margin-bottom: 2rem;
  }

  p {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1rem;
    color: var(--color-text-700);

    &:last-child {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
      margin-top: 1rem;
      color: var(--color-text-700);
    }
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
    margin-top: 1rem;

    input {
      width: 100%;
      height: 3rem;
      padding: 0.875rem 1rem;
      border: 1px solid var(--color-background-300);
      border-radius: 0.5rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: var(--color-text-500);

      &.inputError {
        border: 1px solid var(--color-negative-700);
      }
    }

    button {
      &[type='submit'] {
        width: 80%;
        min-width: 12rem;
        max-width: 20rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        align-self: center !important;
        cursor: pointer;

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }

    .inputsContainer {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;

      .input {
        span {
          font-size: 0.75rem;
          font-weight: 500;
          line-height: 1rem;
          padding: 0 1rem;
          color: var(--color-negative-700);
        }
      }
    }
  }
}

@include breakpoint('laptop') {
  .container {
    width: 500px;

    h1 {
      font-size: 1.5rem;
    }

    p {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    form {
      .inputsContainer {
        flex-direction: row;

        .input {
          flex: 1;
        }
      }
    }
  }
}
