.nav {
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.875rem;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;

      label {
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.25rem;
        padding-top: 0.5rem;
        color: var(--color-brand-300);
        opacity: 0.5;
        transition: opacity 0.5s linear;
        cursor: pointer;
      }

      button {
        width: fit-content;
        height: fit-content;
        background: var(--color-background-white) !important;
        border: 1px solid var(--color-brand-300) !important;
        color: var(--color-brand-300) !important;
        transition:
          background 0.5s linear,
          color 0.5s linear,
          transform 0.25s ease;

        svg {
          width: 50px;
        }
      }

      &.selected {
        button {
          background: var(--color-brand-300) !important;
          border: none !important;
          color: var(--color-background-white) !important;
          transition:
            background 0.5s linear,
            color 0.5s linear,
            transform 0.25s ease;
        }

        label {
          opacity: 1;
          transition: opacity 0.5s linear;
        }
      }
    }
  }
}
